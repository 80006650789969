import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BaseComponent} from './views/layout/base/base.component';
import {ErrorPageComponent} from './views/pages/error-page/error-page.component';
import {UserUnauthorizedPageComponent} from './shared/pages/user-unauthorized-page/user-unauthorized-page.component';
import {redirectToStartpageBasedOnProfileGuard} from "./core/guards/redirect-to-startpage-based-on-profile.guard";
import {RedirectorComponent} from "./views/pages/redirector/redirector.component";
import {requireLoginGuard} from "./core/guards/require-login.guard";
import {canDeactivateGuard} from "./core/guards/can-deactivate.guard";
import {ClassicComponent} from "./modules/domino-wrapper/classic.component";
import {IconsListComponent} from "./shared/pages/icons-list.component";


const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    component: BaseComponent,
    children: [
      {
        path: 'documents',
        loadChildren: () => import('./modules/document/document.module').then(m => m.DocumentModule)
      },
      {
        path: 'collections',
        loadChildren: () => import('./modules/collections/collections.module').then(m => m.CollectionsModule)
      },
      {
        path: 'attachments',
        loadChildren: () => import('./modules/attachments/attachments.module').then(m => m.AttachmentsModule)
      },
      {
        path: 'favorites',
        canActivate: [requireLoginGuard],
        loadChildren: () => import('./modules/favorites/favorites.module').then(m => m.FavoritesModule)
      },
      {
        path: 'subscriptions',
        canActivate: [requireLoginGuard],
        loadChildren: () => import('./modules/subscriptions/subscriptions.module').then(m => m.SubscriptionsModule)
      },
      {
        path: 'legacy/my-page',
        canActivate: [requireLoginGuard],
        canDeactivate: [canDeactivateGuard],
        component: ClassicComponent,
        data: {title: 'Min side', pageContentNoPadding: true}
      },
      {
        path: 'legacy/author',
        canActivate: [requireLoginGuard],
        canDeactivate: [canDeactivateGuard],
        component: ClassicComponent,
        data: {title: 'Forfatter', pageContentNoPadding: true}
      },
      {
        path: 'legacy/editor',
        canActivate: [requireLoginGuard],
        canDeactivate: [canDeactivateGuard],
        component: ClassicComponent,
        data: {title: 'Redaktør', pageContentNoPadding: true}
      },
      {
        path: 'legacy/admin',
        canActivate: [requireLoginGuard],
        canDeactivate: [canDeactivateGuard],
        component: ClassicComponent,
        data: {title: 'Administrator', pageContentNoPadding: true}
      },
      {
        path: 'legacy/qreg',
        canActivate: [requireLoginGuard],
        canDeactivate: [canDeactivateGuard],
        component: ClassicComponent,
        data: {title: 'Qreq', pageContentNoPadding: true}
      },
      {
        path: 'legacy/review',
        canActivate: [requireLoginGuard],
        canDeactivate: [canDeactivateGuard],
        component: ClassicComponent,
        data: {title: 'Høring', pageContentNoPadding: true}
      },
      {
        path: 'legacy/search-old',
        canActivate: [],
        canDeactivate: [canDeactivateGuard],
        component: ClassicComponent,
        data: {title: 'Gammel søg', pageContentNoPadding: true}
      },
      {
        path: '',
        pathMatch: 'full',
        component: RedirectorComponent,
        canActivate: [redirectToStartpageBasedOnProfileGuard]
      },
      {
        path: 'unauthorized',
        component: UserUnauthorizedPageComponent,
        pathMatch: 'full',
        data: {title: 'Du har ikke adgang til denne side'}
      },
      {
        path: 'error',
        component: ErrorPageComponent,
        data: {
          'type': ' ',
          'title': 'Side ikke fundet',
          'desc': 'Den side du leder efter findes ikke.'
        }
      },
      {
        path: 'icons',
        component: IconsListComponent,
        data: {title: 'Ikon liste'}
      },
      {
        path: '**',
        component: ErrorPageComponent,
        pathMatch: 'full',
        data: {
          'type': ' ',
          'title': 'Side ikke fundet',
          'desc': 'Den side du leder efter findes ikke.'
        }
      }
    ]
  },


  {
    path: 'error/:type',
    component: ErrorPageComponent
  },

  {path: '**', redirectTo: 'error', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top', anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

