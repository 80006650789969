import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {CoreConfig} from '../core.config';
import {
  CollectionDocumentTreeItem,
  CollectionDocumentTreeResponse,
  CollectionInfo,
  CollectionListItem
} from '../models/document.model';
import {map} from "rxjs/operators";
import {
  DominoArrayResponse,
  DominoCollectionDocumentTreeResponse,
  DominoCollectionInfo,
  DominoCollectionListItem,
  DominoResponse
} from "../models/domino-response.model";

@Injectable({
  providedIn: 'root'
})
export class CollectionResource {

  constructor(
    public httpClient: HttpClient,
    public coreConfig: CoreConfig,
  ) {

  }

  public getCollections(publisher = 'ALL'): Observable<CollectionListItem[]> {
    return this.httpClient.get<DominoArrayResponse<DominoCollectionListItem>>(`${this.coreConfig.api.baseUrl}/${this.coreConfig.dominoConfig.collectionDatabase}/GetCollections?OpenAgent&publisher=${publisher}`, {
      withCredentials: true
    }).pipe(
        map(response => this.mapCollectionListItems(response.data))
    );
  }

  public getCollectionInfo(id: string) {
    return this.httpClient.get<DominoResponse<DominoCollectionInfo>>(`${this.coreConfig.api.baseUrl}/${this.coreConfig.dominoConfig.collectionDatabase}/GetCollectionInfo?OpenAgent&collectionid=${id}`, {
      withCredentials: true
    }).pipe(
        map(response => {
          if (!response.success) {
            throw new HttpErrorResponse({status: 404, statusText: 'Samlingen ikke fundet'});
          }
          return response;
        }),
        map(response => response.data),
        map(collection => {
          return {
            id: id,
            title: collection.Title,
            frontpage: collection.Frontpage,
            startCollapsed: collection.startcollapsed === '1',
            isFavorite: collection.showFavorite !== '1',
            isSubscribed: collection.showSubscription === '0',
          } as CollectionInfo;
        })
    );
  }

  public getCollectionDocumentTree(id: string) {
    return this.httpClient.get<DominoCollectionDocumentTreeResponse>(`${this.coreConfig.api.baseUrl}/${this.coreConfig.dominoConfig.collectionDatabase}/LoadCollectionTree?OpenAgent&collectionid=${id}`, {
      withCredentials: true
    }).pipe(
      map(response => this.mapCollectionDocumentTreeResponse(response)),
    );
  }

  private mapCollectionDocumentTreeResponse(response: DominoCollectionDocumentTreeResponse): CollectionDocumentTreeResponse {
    return response.map(item => {
        const node = {
          id: item.id,
          title: item.text,
          documentId: item?.docid?.replace("-", "") || null,
          dbfilepath: item.dbfilepath,
          expandable: item.expandable,
          expanded: item.expanded,
          isAttachment: item.isAttachment ?? false,
          attachmentUrl: item.target,
          children: this.mapCollectionDocumentTreeResponse(item?.children ?? []),
        } as CollectionDocumentTreeItem;

        node.isDocument = ! item.isAttachment && !! node.documentId;
        return node;
      });
  }


  private mapCollectionListItems(data: DominoCollectionListItem[]) {
    return data.map(item => {
      return {
        id: item.collectionid,
        documentId: item.docid,
        title: item.title,
        version: item.version,
        status: item.status,
        publisher: item.publisherdisplay,
        publisherId: item.publisherid,
        updatedBy: item.modifiedname,
        updatedAt: item.modifiedtime,
        image: this.coreConfig.api.baseUrl + item.image,
        hideTitle: item.hidetitle,
        sort: item.sort,
        sortDisplay: item.sortdisplay,
    }}) as CollectionListItem[];
  }
}
