import {inject, Injectable} from '@angular/core';
import {from, Observable, ReplaySubject, tap, zip} from 'rxjs';
import {Organisation} from '../../models/organisation.model';
import {OrganisationResource} from '../../resources/organisation-resource';
import {filter, map} from "rxjs/operators";
import {
  OrganisationSelectorComponent
} from "../../../shared/components/organication-selector/organisation-selector.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {
  private organisationResource = inject(OrganisationResource);
  private modalService = inject(NgbModal);

  private allOrganisations: Organisation[] = [];
  public organisations$ = new ReplaySubject<Organisation[]>(1);
  public publishers$ = new ReplaySubject<Organisation[]>(1);

  public loadOrganisations() {
    this.organisationResource.getOrganisations().subscribe((organisations) => {
      // the 0000 publisher is a special organisation that is used to represent the regional level
      const orgsWithoutRegional = organisations.filter(org => org.id !== '0000');
      this.publishers$.next(this.getPublishers(organisations));
      this.organisations$.next(orgsWithoutRegional);
      this.allOrganisations = organisations;
    });
  }

  public getOrganisationById(id: string): Organisation | null {
    return this.allOrganisations.find((item) => item.id === id) ?? null;
  }

  selectOrganisation(singleSelect = false, selectedIds: string[] = [], selectPublishers = true) {
    const modal = this.modalService.open(OrganisationSelectorComponent);
    modal.componentInstance.singleSelect = singleSelect;
    modal.componentInstance.selectedIds = selectedIds;
    modal.componentInstance.selectPublishers = selectPublishers;

    return from(modal.result).pipe(
      filter((value) => Array.isArray(value)),
      map((ids: string[]) => ids),
    );
  }

  private getPublishers(organisations: Organisation[]) {
    // filter out level3 organisations as they are not publishers
    return organisations.filter(org => org.id.length <= 6);
  }
}
