import {Injectable} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from "@angular/material/icon";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class IconService {

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private http: HttpClient
  ) {
  }

  registerIcons(): void {

    this.matIconRegistry.addSvgIconSet(
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/sprite.css.svg')
    );
  }

  getIconNames(): Observable<string[]> {
    return this.http.get('assets/sprite.css.svg', { responseType: 'text' })
      .pipe(
        map(svgContent => {
          const parser = new DOMParser();
          const svgDoc = parser.parseFromString(svgContent, 'image/svg+xml');
          const icons: string[] = [];

          // Find all svg elements with IDs that start with 'icon-'
          const svgElements = svgDoc.querySelectorAll('svg[id^="icon-"]');

          svgElements.forEach(element => {
            const id = element.getAttribute('id');
            if (id) {
              // Remove the 'icon-' prefix if you want just the name
              // or keep it if that's how you register them
              icons.push(id);
            }
          });

          return icons;
        })
      );
  }
}
