import {Injectable} from '@angular/core';
import {CollectionResource} from "../resources/collection-resource";
import {from, Observable} from "rxjs";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CollectionSelectorComponent} from "../../shared/components/collection-selector/collection-selector.component";
import {CollectionListItem} from "../models/document.model";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CollectionService {

  private collectionCache: { [key: string]: CollectionListItem[] } = {};

  constructor(
    private collectionResource: CollectionResource,
    private modalService: NgbModal,
  ) {
  }

  public getCollections(publisher: string, forceUpdate = false): Observable<CollectionListItem[]> {
    if (this.collectionCache[publisher] && !forceUpdate) {
      return from([this.collectionCache[publisher]]);
    }
    return this.collectionResource.getCollections(publisher).pipe(
      map((collections) => {
        this.collectionCache[publisher] = collections;
        return collections;
      }),
    );
  }

  public getCollectionInfo(id: string) {
    return this.collectionResource.getCollectionInfo(id);
  }

  public getCollectionDocumentTree(id: string) {
    return this.collectionResource.getCollectionDocumentTree(id);
  }

  selectCollection(publisherId: string): Observable<CollectionListItem | null> {
    const modal = this.modalService.open(CollectionSelectorComponent);
    modal.componentInstance.filterPublisherId = publisherId;
    modal.componentInstance.singleSelect = true;
    modal.componentInstance.allowEmpty = true;

    return from(modal.result);
  }


}
