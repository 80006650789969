<nav class="navbar">
  <a href="" class="sidebar-toggler" (click)="toggleSidebar($event)">
    <mat-icon svgIcon="icon-menu"></mat-icon>
  </a>
  <div class="navbar-content">
    <!--<form class="search-form">
      <div class="input-group">
        <div class="input-group-text">
          <i class="feather icon-search" appFeatherIcon [attr.data-feather]="'search'"></i>
        </div>
        <input type="text" class="form-control" id="navbarForm" placeholder="Søg her">
      </div>
    </form>-->
    <ng-container *ngIf="coreConfig.dominoConfig.whitelabel.topbarannouncement">
      <div class="my-auto">
          <a class="announcement"
             (click)="showAnnouncement()"
                [style.color]="'#' + coreConfig.dominoConfig.whitelabel.topbarannouncementcolor">
            {{  coreConfig.dominoConfig.whitelabel.topbarannouncement }}
          </a>
      </div>
    </ng-container>
    <ul class="navbar-nav">

      <li class="nav-item" *ngIf=" ! profileStateService.isLoggedIn()">
        <ng-container *ngIf="coreConfig.appId === 'vip' || coreConfig.appId === 'viptest'; else normalLogin">
          <a href="https://fs.regionh.dk/adfs/ls/IdpInitiatedSignOn.aspx?loginToRp=https://vip.regionh.dk" class="text-nowrap">
            <span style="vertical-align: text-bottom">Log ind</span>
            <i class="mdi mdi-login fs-2 ms-2"></i>
          </a>
        </ng-container>
        <ng-template #normalLogin>
          <a routerLink="/auth/login" class="text-nowrap">
            <span style="vertical-align: text-bottom">Log ind</span>
            <i class="mdi mdi-login fs-2 ms-2"></i>
          </a>
        </ng-template>
      </li>
      <ng-container *ngIf="profileStateService.isLoggedIn()">
        <li class="nav-item nav-profile" ngbDropdown>
          <a class="nav-link" ngbDropdownToggle id="profileDropdown">
            <span class="d-none d-md-inline" style="vertical-align: super">
              {{ this.profileStateService.profile()?.firstname }} {{ this.profileStateService.profile()?.lastname }}
            </span>
            <mat-icon svgIcon="icon-user" class="ms-2"></mat-icon>
          </a>
          <div ngbDropdownMenu class="px-0" aria-labelledby="profileDropdown">
            <div class="d-flex flex-column align-items-center border-bottom px-5 py-3">
              <div class="text-center">
                <p class="tx-16 fw-bolder">{{ this.profileStateService.profile()?.firstname }} {{ this.profileStateService.profile()?.lastname }}</p>
                <p class="tx-13 text-muted mt-3">
                  <app-organisation-name [id]="this.profileStateService.profile()?.level1?.default" class="d-block"></app-organisation-name>
                  <app-organisation-name *ngIf="this.profileStateService.profile()?.level2?.default" [id]="this.profileStateService.profile()?.level2?.defaultFull"  class="d-block">></app-organisation-name>
                  <app-organisation-name *ngIf="this.profileStateService.profile()?.level3?.default" [id]="this.profileStateService.profile()?.level3?.defaultFull" class="d-block">></app-organisation-name>
                </p>
              </div>
            </div>
            <ul class="list-unstyled p-1">
              <li class="dropdown-item py-2">
                <a (click)="openProfilModal()" class="d-flex text-body ms-0  align-items-center">
                  <mat-icon svgIcon="icon-user" class="me-2"></mat-icon>
                  <span>Min profil</span>
                </a>
              </li>
              <li class="dropdown-item py-2">
                <a href="" (click)="onLogout($event)" class="d-flex text-body ms-0 align-items-center">
                  <i class="mdi mdi-logout me-2 ms-2 fs-4"></i>
                  <span>Log ud</span>
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
</nav>
