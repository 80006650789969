import {Component, OnInit} from '@angular/core';
import {AuthService} from "./core/services/auth.service";
import {OrganisationService} from "./core/services/organisation/organisation.service";
import {TitleService} from "./core/services/title.service";
import {NavigationStart, Router} from "@angular/router";
import {filter} from "rxjs/operators";
import {NgbModal, NgbTooltipConfig} from "@ng-bootstrap/ng-bootstrap";
import {CoreConfig} from "./core/core.config";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {IconService} from "./core/services/icon.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [NgbTooltipConfig],
})
export class AppComponent implements OnInit {
  title = '';

  constructor(
    private authService: AuthService,
    private organisationService: OrganisationService,
    private titleService: TitleService,
    private router: Router,
    private modalService: NgbModal,
    private coreConfig: CoreConfig,
    private ngbTooltipConfig: NgbTooltipConfig,
    private iconService: IconService,
  ) {
  }

  ngOnInit(): void {
    this.authService.pollLoginStatus();
    this.organisationService.loadOrganisations();
    this.titleService.init(' - ' + this.coreConfig.dominoConfig.company);

    this.closeModalsOnNavigation();
    this.setWhitelabelDesignConfig();
    this.setTooltipConfig();

    this.iconService.registerIcons();
  }



  private setWhitelabelDesignConfig() {
    document.documentElement.style.setProperty('--brand-color', "#" + this.coreConfig.dominoConfig?.whitelabel?.brandcolor);
    document.documentElement.style.setProperty('--publisher-level-0', "#" + this.coreConfig.dominoConfig?.whitelabel?.publisherlevel0);
    document.documentElement.style.setProperty('--publisher-level-1', "#" + this.coreConfig.dominoConfig?.whitelabel?.publisherlevel1);
    document.documentElement.style.setProperty('--publisher-level-2', "#" + this.coreConfig.dominoConfig?.whitelabel?.publisherlevel2);

    document.querySelector('link[rel="icon"]')?.setAttribute('href', this.coreConfig.api.baseUrl + '/' + this.coreConfig.dominoConfig.guidatabase + this.coreConfig.dominoConfig?.whitelabel?.favicon);
  }

  private closeModalsOnNavigation() {
    // this is here to not have to listen to router events in every component that opens a modal.
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart),
    ).subscribe(data => this.modalService.dismissAll());
  }

  private setTooltipConfig() {
    this.ngbTooltipConfig.placement = 'auto';
    this.ngbTooltipConfig.triggers = 'hover';
    this.ngbTooltipConfig.openDelay = 200;
  }
}

