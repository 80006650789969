import {Component, inject, signal} from '@angular/core';
import { CommonModule } from '@angular/common';
import {IconService} from "../../core/services/icon.service";
import {MatIconModule} from "@angular/material/icon";

@Component({
  selector: 'app-icons-list',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  template: `
    <div class="row row-responsive-margin">
      <div class="col-12 mb-2 mb-md-4">
        <h2>Icons</h2>
      </div>
      <div class="col-12 mb-2 mb-md-4">
        <div class="row">
          <div class="col">
            <div class="card">
              <div class="card-body">
                <ul>
                  <li *ngFor="let icon of icons()">
                    <mat-icon [svgIcon]="icon" class="me-2"></mat-icon>
                    <span>{{ icon }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class IconsListComponent {
  iconService = inject(IconService);
  icons = signal<string[]>([]);
  ngOnInit() {
    this.iconService.getIconNames().subscribe((icons) => {
      this.icons.set(icons);
    });
  }

}
